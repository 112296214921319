import React from "react"
// import arrow from "../../static/arrow.svg"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import tw from "tailwind.macro"

const Lead = styled.p`
  ${tw`text-half`};
`
const Cta = styled.div`
  ${tw`mx-6 md:ml-16 text-center text-sm max-w-md`};
  a > img {
    position: relative;
    top: 0.4rem;
    margin: 0 0 0 1rem;
  }
`
const InternalLink = styled(Link)`
  ${tw`bg-primary hover:bg-primary-dark text-background font-medium no-underline py-4 px-6 block rounded-sm`}
`
const ExternalLink = styled.a`
  ${tw`bg-primary hover:bg-primary-dark text-background font-medium no-underline py-4 px-6 block rounded-sm`}
`

const Actions = props => (
  <Cta>
    {props.goTo ? (
      <InternalLink to={props.goTo}>{props.buttonText}</InternalLink>
    ) : (
      <ExternalLink
        href={props.linkTo}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.buttonText}
      </ExternalLink>
    )}
    <Lead>{props.text}</Lead>
  </Cta>
)

export default Actions
