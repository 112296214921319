import React from "react"
import { Global, css } from "@emotion/core"
import Header from "../components/header"
import tw from "tailwind.macro"
import map from "../../static/map.svg"
import SEO from "./SEO"

export default ({ children, customSEO }) => (
  <div>
    <Global
      styles={css`
        body {
          margin: 0;
          ${tw`bg-background text-half`};
          font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
            "Helvetica Neue", "Arial", "Noto Sans", sans-serif;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-image: url(${map});
          background-repeat: no-repeat;
          background-size: 200%;
          background-position: 50% 0;
          @media (min-width: 768px) {
            background-size: 80%;
            background-position: 250% 50px;
          }
        }
        h1 {
          ${tw`leading-tight text-3xl sm:text-4xl font-semibold m-0`};
        }
        h2 {
          ${tw`leading-tight text-xl text-white font-semibold mt-12`};
        }
        h3 {
          ${tw`text-base`};
        }
        p,
        ol,
        ul,
        blockquote {
          ${tw`leading-snug`};
        }
        a {
          transition: all 75ms ease-in-out;
        }
        blockquote {
          ${tw`bg-yellow-light text-full rounded p-4 pb-6 m-0 text-center`}
          span {
            ${tw`block text-3xl`}
          }
        }
      `}
    />
    {!customSEO && <SEO />}
    <Header />
    {children}
  </div>
)
