import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

const Header = styled.header`
  ${tw`p-6 md:pl-16 lg:mt-20 text-center md:text-left max-w-lg`};
`

const Title = styled.h1`
  ${tw`text-white`};
  a {
    ${tw`no-underline text-primary hover:text-primary-dark`};
  }
`
const Lead = styled.p`
  ${tw`text-half text-xl mb-0`};
  a {
    ${tw`no-underline text-primary hover:text-primary-dark`};
  }
`
const Hero = props => (
  <Header>
    <Title dangerouslySetInnerHTML={{ __html: props.title }} />
    <Lead dangerouslySetInnerHTML={{ __html: props.text }} />
  </Header>
)

export default Hero
