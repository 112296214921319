/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import homeIcon from "../../static/home.svg"
import learnIcon from "../../static/learn.svg"
import helpIcon from "../../static/help.svg"
//import resultsIcon from "../../static/results.svg"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import tw from "tailwind.macro"

const Nav = styled.nav`
  ${tw`relative flex items-center m-6 md:mx-16 z-20`};
`
const Profile = styled.div`
  ${tw`w-1/5`};
  max-width: 75px;
`
const avatar = css`
  min-width: 50px;
  border-radius: 100%;
`
const Intro = styled.div`
  ${tw`text-sm w-4/5 text-white`};
  a {
    ${tw`no-underline text-primary hover:text-half`};
  }
`
const Menu = styled.ul`
  ${tw`flex fixed md:flex-auto md:relative bottom-0 left-0 right-0 list-none bg-background md:bg-transparent m-0 p-3 z-50`};
`
const ListItem = styled.li`
  ${tw`flex-1 mr-2 text-center`};
`
const StyledLink = styled(Link)`
  ${tw`block no-underline text-white opacity-60 hover:opacity-100 text-xs md:text-sm md:px-2 font-medium text-center`};
  &[aria-current] {
    ${tw`opacity-100`};
  }
  img {
    ${tw`block my-0 mx-auto md:hidden`};
    width: 32px;
    height: 32px;
  }
`
const ListLink = props => (
  <ListItem>
    <StyledLink to={props.to}>{props.children}</StyledLink>
  </ListItem>
)

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      site {
        siteMetadata {
          author
          titleAlt
        }
      }
    }
  `)

  const { author, titleAlt } = data.site.siteMetadata
  return (
    <Nav>
      <Profile>
        <Img
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          css={avatar}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      </Profile>
      <Intro>
        <p
          css={css`
            margin: 0;
          `}
        >
          <Link to="/contact/">{author}</Link> is a {titleAlt}
        </p>
      </Intro>
      <Menu>
        <ListLink to="/">
          <img src={homeIcon} alt="Home" />
          Home
        </ListLink>
        <ListLink to="/learn/">
          <img src={learnIcon} alt="Learn" />
          Learn
        </ListLink>
        <ListLink to="/contact/">
          <img src={helpIcon} alt="Contact" />
          Contact
        </ListLink>
      </Menu>
    </Nav>
  )
}

export default Header

// Was using this for a monotone avatar
// duotone: { highlight: "#5a4fff", shadow: "#191f50", opacity: 10 }
